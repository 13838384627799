import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import FormView from "@/views/FormView";
import HomeView from "@/views/HomeView";
import UploadDocView from "@/views/UploadDocView";
import store from '../store'
import DownloadProposal from "@/views/DownloadProposal.vue";
import StartEkycView from "@/views/StartEkycView.vue";
import DocumentSummaryView from "@/views/DocumentSummaryView.vue";
import settings from "@/composables/useSettings";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      middleware: 'guest',
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      middleware: 'auth',
    }
  },

  {
    path: '/quote',
    name: 'quote',
    component: FormView,
    meta: {
      middleware: 'auth',
    }
  },

  {
    path: '/quote/upload-doc',
    name: 'upload-doc',
    component: UploadDocView,
    meta: {
      middleware: 'homeOwner',
    }
  },

  {
    path: '/download-proposal',
    name: 'download-proposal',
    component: DownloadProposal,
    // TODO:: this is a public route, if we use auth, whoever access this route from embeded project at backend will not have any authentication.
    // meta: {
    //   middleware: 'guest',
    // }
  },

  {
    path: '/start-ekyc',
    name: 'start-ekyc',
    component: StartEkycView,
    meta: {
      middleware: 'auth',
      requiresEkyc: true
    }
  },

  {
    path: '/document-summary',
    name: 'document-summary',
    component: DocumentSummaryView,
    meta: {
      middleware: 'auth',
    }
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})


router.beforeEach(async (to, from, next) => {
  const key = to.query.key;

  if (to.name === 'start-ekyc') {
    try {
      // If there's a key in the URL, try auto-login first
      if (key) {
        const success = await store.dispatch('auth/autoLogin', key)
        if (!success) {
          next({ name: 'login' })
          return
        }
      }

      // Only fetch settings if they haven't been loaded
      if (!settings.getSettings()) {
        await settings.fetchSettings()
      }

      if (!settings.isEkycEnabled()) {
        next({ name: 'quote' })
        return
      }
    } catch (error) {
      console.error('Failed to fetch settings:', error)
      next({ name: 'quote' })
      return
    }
  }

  if (to.meta.middleware === "guest") {
    if(store.state.auth.authenticated) {
      next({name: 'home'})
    }
    next()
  } else if (to.meta.middleware === "homeOwner") {
    next()
  } else {
    if (store.state.auth.authenticated || to.query.key || to.query.userId || to.query.proposalData) {
      next()
      
      if (store.state.auth.authenticated && process.env.VUE_APP_ENV === 'production') {
        const userId = store.state.auth.user.id;
        router.app.$mixpanel.identify(userId);
        
        // Set user properties
        router.app.$mixpanel.people.set({
          '$name': store.state.auth.user.name,
          '$email': store.state.auth.user.email,
        });
        
        console.log('Mixpanel identified user with user ID: ', store.state.auth.user.id, ', name: ', store.state.auth.user.name, ', email: ', store.state.auth.user.email);
      }
    } else {
      next({name: 'login'})
    }
  }
})

export default router
