<template>
  <div>
    <div id="selfie-cam">
      <main id="liveness">
        <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Selfie</div>
        <div v-if="!loading" class="overlay"></div>
        <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
        <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
        <img src="//:0" alt="" id="camera--output" ref="cameraOutputId">
        <button class="z-50" type="button" @click="takeSelfie" id="camera--trigger" :disabled="loading">
          <span v-if="!loading">Take a picture</span>
          <span v-else>Loading...</span>
        </button>
        <div v-if="loading" class="full-screen-overlay">
          <div class="loader z-50"></div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  name: "SelfieCamera",
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      key: null,
      loading: false,
      constraints: {
        video: {
          facingMode: "user",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64SelfieData: "",
      mediaStream: null,
    };
  },
  mounted() {
    console.log(this.errorCount)
    const journeyId = sessionStorage.getItem('journeyId');
    if (!journeyId) {
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    closeCamera() {
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    async centralizedOkayFace(journeyId, selfieImage) {
      try {
        const response = await this.$http.post('/api/centralize-okay-face', { journeyId, selfieImage });
        console.log(response.data);
        return response;
      } catch (error) {
        console.error('Error centralizing Okay face:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          if (error.response.status === 500) {
            this.takeSelfie(); // Retry taking the selfie
          } else {
            // Handle other status codes or errors if necessary
          }
        } else {
          // Handle errors not related to response (e.g., network issues)
        }
      }
    },
    startCamera() {
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("Oops. Something is broken.", error);
          });
    },
    async takeSelfie() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc Selfie Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc Selfie Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.loading = true;

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;
      const cameraOutputId = this.$refs.cameraOutputId;

      // Set canvas size to match the video stream
      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;

      // Draw the current frame from the video onto the canvas
      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);

      // Get the data URL of the image
      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      cameraOutputId.src = imageDataUrl;
      cameraOutputId.classList.add("taken");

      // Extract the base64 string from the data URL
      this.base64SelfieData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');

      const key = this.$route.query.key; // Get the key from the current URL
      // Get the journeyId from sessionStorage
      const journeyId = sessionStorage.getItem('journeyId');

      this.closeCamera();      // Send the journeyId and base64 image to the backend
      try {
        let okayFaceResponse = await this.centralizedOkayFace(journeyId, this.base64SelfieData);
        if (okayFaceResponse.data && okayFaceResponse.data.status === 'success') {
          this.$toast.success('Images Uploaded Successfully');
          const journeyId = sessionStorage.getItem('journeyId');
          if (journeyId){
            this.$router.push({ name: 'document-summary', query: { JID : journeyId, key } });
          }else {
            console.error('Journey ID is missing.');
          }
        } else {
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc selfie captured unsuccessfully due to inappropriate image format', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc Selfie Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId')
              });
            } catch (error) {
              console.log('Mixpanel tracking error: ', error);
            }
          }

          this.$toast.error('Inappropriate image format, please retake');
          this.handleError();
          this.loading = false;
        }
      } catch (error) {
        console.error('Error saving Journey ID and image:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        this.loading = false; // Ensure the loading state is reset on error
      }
    },
    handleError() {
      console.log(this.errorCount);

      if (this.errorCount >= 1) {
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          this.$router.push({ name: 'document-summary', query: { JID : journeyId } });
        }else {
          console.error('Journey ID is missing.');
        }
      } else {
        this.startCamera();
        this.$emit('failed', { type:"selfie" });
      }
    }
  }
};
</script>
