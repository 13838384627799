<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Render based on the current step -->
    <div v-if="step === 0">
      <h1 class="text-2xl font-semibold mb-4">Select Document Type</h1>
      <div class="bg-white shadow-md rounded-lg p-6">
        <form>
          <div class="flex items-center mb-4">
            <input
                id="mykad"
                type="radio"
                value="mykad"
                v-model="selectedOption"
                class="mr-2"
            />
            <label for="mykad" class="text-sm font-medium text-gray-900">Mykad</label>
          </div>
          <div class="flex items-center mb-4">
            <input
                id="passport"
                type="radio"
                value="passport"
                v-model="selectedOption"
                class="mr-2"
            />
            <label for="passport" class="text-sm font-medium text-gray-900">Passport</label>
          </div>
        </form>
      </div>
      <div class="pt-5">
        <button class="px-8 py-4 text-white bg-blue-500" @click="proceedToNext">
          <span>Next</span>
        </button>
      </div>
      <!-- <div class="pt-5">
        <button class="px-8 py-4 text-white bg-blue-500" @click="generateJourneyId" :disabled="loading">
          <span v-if="!loading">Start E-KYC</span>
          <span v-else>Loading...</span>
        </button>
      </div> -->
    </div>
    <div v-else-if="step === 1">
      <PhotoGuide @next-step="generateJourneyId"/>
    </div>
    <div v-else-if="step === 2">
      <IDCaptureCam :error-count="errorCount" @next-step="goToNextStep" @failed="failedResponse"/>
    </div>
    <div v-else-if="step === 3">
      <IDBackCaptureCam :error-count="errorCount" @next-step="goToNextStep" @failed="failedResponse" />
    </div>
    <div v-else-if="step === 4">
      <SelfieCam :error-count="errorCount" @next-step="goToNextStep" @failed="failedResponse"/>
    </div>
  </div>
</template>

<script>
import PhotoGuide from "@/components/PhotoGuide.vue";
import IDBackCaptureCam from "@/components/IDBackCaptureCam.vue";
import IDCaptureCam from "@/components/IDCaptureCam.vue";
import SelfieCam from "@/components/SelfieCam.vue";
import store from '../store';
import settings from "@/composables/useSettings";
import { mapActions } from 'vuex'

export default {
  components: {
    PhotoGuide,
    IDCaptureCam,
    IDBackCaptureCam,
    SelfieCam,
  },
  data() {
    return {
      // loading: false,
      selectedOption: 'mykad',
      step: 0,
      errorCount: 0,
    };
  },
  mounted() {
    console.log("isEkycEnabled:", settings.isEkycEnabled());

    sessionStorage.clear();
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');

    if (key) {
      const success = await this.autoLogin(key)
      if (!success) {
        this.$toast.error('Invalid or expired link')
        this.$router.push({ name: 'login' })
        return
      }
    } else if (!this.store.state.auth.authenticated) {
      this.$router.push({ name: 'login' })
      return
    }

    // Check access before mounting component
    // if (!await this.checkEkycAccess()) {
    //   await this.$router.push({name: 'quote'})
    // }
  },
  computed: {
    retryCount() {
      return parseInt(localStorage.getItem('retryCount') || '0');
    },
  },
  methods: {
    ...mapActions({
      autoLogin: 'auth/autoLogin'
    }),
    async checkEkycAccess() {
      if (!settings.getSettings()) {
        await settings.fetchSettings()
      }
      return settings.isEkycEnabled() && settings.canRetryEkyc()
    },
    proceedToNext() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc document type selected, next button clicked', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc document type selection',
            selected_document_type: this.selectedOption,
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.step = 1;
      // if (this.selectedOption === 'mykad') {
      //   this.step = 1; // PhotoGuide
      // } else {
      //   this.generateJourneyId();
      //   this.step = 2; // IDCaptureCam
      // }
    },
    async generateJourneyId() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc photo guide page next button clicked, generating journey ID... ', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc photo guide page',
            selected_document_type: this.selectedOption,
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      const currentRetryCount = this.retryCount + 1;
      localStorage.setItem('retryCount', currentRetryCount);

      try {
        // this.loading = true;
        const response = await this.$http.post('/api/create-journey-id', {
          documentType: this.selectedOption,
        });
        const journeyId = response.data.journey_id;
        console.log('Generated Journey ID:', journeyId);

        sessionStorage.setItem('journeyId', journeyId);
        sessionStorage.setItem('documentType', this.selectedOption);

        this.step = 2; // Move to the next step
        // this.loading = false;
      } catch (error) {
        // this.loading = false;
        console.error('Error generating Journey ID:', error);
        if (error.response.status === 401) {
          const urlParams = new URLSearchParams(window.location.search);
          const key = urlParams.get('key');
          if (key) {
            const success = await this.autoLogin(key)
            if (success) {
              return this.generateJourneyId()
            }
          }
          this.$router.push({ name: 'login' })
        } else {
          this.$toast.error('Failed to generate journey ID. Please try again.');
        }
      }
    },
    goToNextStep(payload) {
      const docType = sessionStorage.getItem('documentType')
      console.log(docType)
      if (docType === 'mykad'){
        this.step += 1;
      }else {
        this.step = 4;
      }
      if (payload){
        this.errorCount = 0;
      }
    },
    failedResponse(payload){
      this.errorCount+=1;
      if (payload.type === "doc"){
        this.step = 2;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const $retryCount = parseInt(localStorage.getItem('retryCount'));

    next(async () => {
      if (settings.isEkycEnabled() && $retryCount === 2) {
        next({ name: 'quote' })
      }
    })
  }
};
</script>
