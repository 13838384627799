export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{},
    },
    getters:{
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        }
    },
    actions:{
        async loginUser({commit}){
            try {
                const {data} = await this._vm.$http.get('/api/user')
                commit('SET_USER', data)
                commit('SET_AUTHENTICATED',true)
                return true
            } catch (error) {
                if (error.response.status === 401) {
                    // Clear invalid access token
                    localStorage.removeItem('accessToken')
                }
                commit('SET_USER', {})
                commit('SET_AUTHENTICATED', false)
                return false
            }
        },
        logout({commit}) {
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false)
        },
        async autoLogin({commit, dispatch}, key) {
            try {
                const response = await this._vm.$http.post('/api/auto-login', {
                    hyperlink_key: key
                })
                
                if (response.data.access_token) {
                    localStorage.setItem('accessToken', response.data.access_token)
                    const success = await dispatch('loginUser')
                    return success
                }
                return false
            } catch (error) {
                console.error('Auto login failed:', error)
                // Clear any existing access token
                localStorage.removeItem('accessToken')
                commit('SET_USER', {})
                commit('SET_AUTHENTICATED', false)
                return false
            }
        }
    }
}
